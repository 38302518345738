import React, { useEffect, useState } from "react"
import styles from "./nav.module.css"
import cn from "classnames"
import Burger from "../icons/burger"
import MondaysSmallIcon from "../icons/mondaysSmall"
import { Link } from "gatsby"

function Nav({ secondary, blog, app }) {
  const [mounted, setMounted] = useState(false)

  // When mounted on client, now we can show the UI
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      const nav = document.querySelector("nav")

      function handleScroll() {
        if (window.pageYOffset > 50) {
          nav.classList.add("shadow", "background-black")
          nav.style.padding = "10px 20px 7px"
          return
        }

        nav.classList.remove("shadow", "background-black")
        nav.style.padding = "20px"
      }

      window.onscroll = handleScroll
    }
  }, [mounted])

  return (
    <div className={styles.container}>
      <div className={styles.fixed}>
        <nav className={cn(styles.nav, secondary && styles.secondary)}>
          <div className={styles.content}>
            <div className={styles.burger}>
              <Link to="/" alt="Mondays Home Icon">
                <MondaysSmallIcon />
              </Link>
            </div>
            <ul className={styles.navItems}>
              <Link to="/#contact">
                <li className={cn(blog && "underline", styles.navLinks)}>
                  Contact
                </li>
              </Link>

              <Link to="/blog">
                <li className={cn(blog && "underline", styles.navLinks)}>
                  Blog
                </li>
              </Link>

              <Link className={cn(styles.login)} to="/app">
                <li>Login</li>
              </Link>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Nav
