import React from "react"

function Burger() {
  return (
    <div className="icon">
      <svg
        id="Component_4_3"
        data-name="Component 4 – 3"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 75 44"
      >
        <rect
          id="Rectangle_50"
          data-name="Rectangle 50"
          width="35"
          height="5"
          fill="#fff"
        />
        <rect
          id="Rectangle_51"
          data-name="Rectangle 51"
          width="70"
          height="5"
          transform="translate(0 18)"
          fill="#fff"
        />
        <rect
          id="Rectangle_52"
          data-name="Rectangle 52"
          width="35"
          height="5"
          transform="translate(37 36)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

export default Burger
