import React, { useContext, createContext, useState } from "react"
import { useEffect } from "react/cjs/react.development"

const FormContext = createContext(null)

export function FormProvider({ children }) {
  const [hidden, setHidden] = useState(true)

  return (
    <FormContext.Provider value={{ hidden, setHidden }}>
      {children}
    </FormContext.Provider>
  )
}

export function useFormContext() {
  return useContext(FormContext)
}
