import React from "react"
import styles from "./hero.module.css"
import HeroLogo from "../../icons/heroLogo"

export default function Hero({ children }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.logo}>
        <HeroLogo />
      </div>
      <div>{children}</div>
    </div>
  )
}
