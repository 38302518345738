import React from "react"
import styles from "./button.module.css"
import cn from "classnames"

function Button({ children, secondary, onClick, style, shadow, large }) {
  return (
    <button
      onClick={onClick}
      className={cn(
        styles.button,
        styles.margin,
        secondary ? styles.secondary : styles.primary,
        shadow && "shadow",
        large && styles.large
      )}
      style={style}
    >
      <div className={styles.text}>{children}</div>
    </button>
  )
}

export default Button
