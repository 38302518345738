import React from "react"
import styles from "./footer.module.css"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/Phone"
import { Link } from "gatsby"
import logo from "../../../static/mondays-logo1.svg"

function Footer() {
  return (
    <footer className={styles.footer} id="contact">
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.copyright}>
            <p className={styles.textWhite}> © {new Date().getFullYear()}</p>
          </div>
          <Link to="/">
            <div className={styles.logo}>
              <img src={logo} alt="Mondays Logo" width="175px" height="auto" />
            </div>
          </Link>
          <div className={styles.icons}>
            <a
              href={"https://www.facebook.com/mondaysukteam/"}
              rel="noreferrer"
              target="_blank"
            >
              <FacebookIcon fontSize={"large"} className="icon" />
            </a>
            <a
              href={"https://www.instagram.com/mondaysuk"}
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon fontSize={"large"} className="icon" />
            </a>
            <a href={"tel:+447340557307"}>
              <PhoneIcon fontSize={"large"} className="icon" />
            </a>
            <a
              href={"mailto:support@mondays.co.uk"}
              rel="noreferrer"
              target="_blank"
            >
              <EmailIcon fontSize={"large"} className="icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
