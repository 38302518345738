import React from "react"

function HeroLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 766.252 435.958"
    >
      <g
        id="Group_32"
        data-name="Group 32"
        transform="translate(-4616.874 -3862.021)"
      >
        <path
          id="Path_103"
          data-name="Path 103"
          d="M5140.955,4011.532l-30.989-117.152a43.451,43.451,0,0,0-76.434-15.4l-22.63,29.387,14.218,18.464,26.263-34.1a20.921,20.921,0,0,1,36.8,7.415l30.989,117.152c3.063,11.582-3.375,19.415-10.423,22.884s-17.183,3.794-24.492-5.7l-119.744-155.5a43.452,43.452,0,0,0-76.434,15.4l-30.989,117.152c-5.307,20.061,3.636,39.7,22.254,48.86s39.632,4.276,52.293-12.166L4987.1,3976.2l-14.218-18.464-59.1,76.746c-7.309,9.491-17.445,9.166-24.492,5.7s-13.486-11.3-10.423-22.884l30.99-117.152a20.921,20.921,0,0,1,36.8-7.415l119.743,155.5c8.406,10.916,20.492,16.74,33.116,16.739a43.347,43.347,0,0,0,19.177-4.573C5137.319,4051.228,5146.262,4031.593,5140.955,4011.532Z"
          fill="#fff"
        />
        <g id="Group_31" data-name="Group 31">
          <g id="Group_29" data-name="Group 29">
            <path
              id="Path_104"
              data-name="Path 104"
              d="M4692.574,4161.962l-28.308,38.324h-3.227l-27.687-38.45V4226h-16.389v-88.246h18.872l27.19,37.946,27.19-37.946h18.747V4226h-16.388Z"
              fill="#fff"
            />
            <path
              id="Path_105"
              data-name="Path 105"
              d="M4836.334,4182.258c-.372,22.818-14.03,45.636-44.572,45.636s-44.7-22.314-44.7-45.51c0-27.86,19.741-46.518,45.069-46.518C4821.808,4136.245,4836.706,4159.314,4836.334,4182.258Zm-44.572,30.382c20.486,0,28.059-15.506,28.432-30.13s-8.07-31.138-28.059-31.516c-18.375,0-29.549,13.993-28.928,31.642C4763.579,4197.134,4771.4,4212.64,4791.762,4212.64Z"
              fill="#fff"
            />
            <path
              id="Path_106"
              data-name="Path 106"
              d="M4933.77,4197.008v-59.377h16.389v88.246h-13.284l-46.186-58.747v58.747H4874.3v-88.246h13.285Z"
              fill="#fff"
            />
            <path
              id="Path_107"
              data-name="Path 107"
              d="M5027.359,4137.631c29.8,0,43.081,21.558,43.454,43.366.373,22.314-13.036,44.88-43.454,44.88h-34.143v-88.246Zm-17.878,72.236h17.878c19.864,0,27.562-14.624,27.19-29-.373-13.742-8.319-27.483-27.19-27.483h-17.878Z"
              fill="#fff"
            />
            <path
              id="Path_108"
              data-name="Path 108"
              d="M5164.624,4209.236h-45.44l-7.45,16.641H5093.98l38.985-88.246h17.879l38.984,88.246H5171.95Zm-22.719-53.074-16.141,37.568h32.28Z"
              fill="#fff"
            />
            <path
              id="Path_109"
              data-name="Path 109"
              d="M5243.067,4174.064l23.092-36.433H5285.9v.757l-34.639,51.056v36.433h-16.389v-36.433l-33.4-51.056v-.757h19.492Z"
              fill="#fff"
            />
            <path
              id="Path_110"
              data-name="Path 110"
              d="M5367.359,4159.819c-2.981-5.043-11.174-9.834-20.611-9.834-12.291,0-18,5.421-18,10.842,0,7.69,8.939,9.707,19.368,11.094,18.251,2.4,35.012,6.933,35.012,28.113,0,19.666-17.133,28.112-36.5,28.112-17.754,0-31.535-5.547-37.867-21.683l13.657-7.186c3.973,9.707,13.781,13.993,24.459,13.993,10.305,0,19.988-3.655,19.988-13.236,0-8.321-8.566-11.6-20.113-12.985-17.754-2.018-34.143-7.06-34.143-26.726,0-16.515,17.631-24.835,33.647-24.835,13.657,0,27.438,3.908,34.142,17.523Z"
              fill="#fff"
            />
          </g>
          <g id="Group_30" data-name="Group 30">
            <path
              id="Path_111"
              data-name="Path 111"
              d="M4616.874,4297.374v-23.582h16.643v4.266h-12.275v6.517h11.613v4.1h-11.613v8.7Z"
              fill="#fff"
            />
            <path
              id="Path_112"
              data-name="Path 112"
              d="M4643.945,4297.374v-23.515h4.335v23.515Z"
              fill="#fff"
            />
            <path
              id="Path_113"
              data-name="Path 113"
              d="M4664.95,4277.89H4657.6v-4.031h19.058v4.031h-7.345v19.484h-4.367Z"
              fill="#fff"
            />
            <path
              id="Path_114"
              data-name="Path 114"
              d="M4701.886,4289.682v-15.823h4.368v23.515h-3.54L4690.4,4281.72v15.654h-4.367v-23.515h3.54Z"
              fill="#fff"
            />
            <path
              id="Path_115"
              data-name="Path 115"
              d="M4735.461,4297.374h-17.337v-23.515h17.337v4.3h-13v5.409H4735v4.132h-12.54v5.307h13Z"
              fill="#fff"
            />
            <path
              id="Path_116"
              data-name="Path 116"
              d="M4760.332,4279.771a6.608,6.608,0,0,0-5.493-2.62c-3.275,0-4.8,1.445-4.8,2.889,0,2.05,2.382,2.587,5.161,2.957,4.864.638,9.33,1.847,9.33,7.491,0,5.24-4.565,7.491-9.727,7.491-4.731,0-8.4-1.478-10.091-5.778l3.639-1.915c1.059,2.587,3.673,3.729,6.519,3.729,2.746,0,5.326-.974,5.326-3.527,0-2.217-2.283-3.091-5.36-3.46-4.731-.538-9.1-1.882-9.1-7.122,0-4.4,4.7-6.618,8.966-6.618,3.64,0,7.312,1.041,9.1,4.669Z"
              fill="#fff"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M4788.917,4279.771a6.606,6.606,0,0,0-5.492-2.62c-3.276,0-4.8,1.445-4.8,2.889,0,2.05,2.382,2.587,5.161,2.957,4.863.638,9.33,1.847,9.33,7.491,0,5.24-4.566,7.491-9.727,7.491-4.732,0-8.4-1.478-10.092-5.778l3.64-1.915c1.059,2.587,3.673,3.729,6.518,3.729,2.746,0,5.327-.974,5.327-3.527,0-2.217-2.283-3.091-5.36-3.46-4.731-.538-9.1-1.882-9.1-7.122,0-4.4,4.7-6.618,8.966-6.618,3.64,0,7.313,1.041,9.1,4.669Z"
              fill="#fff"
            />
            <path
              id="Path_118"
              data-name="Path 118"
              d="M4822.163,4283.836v-5h4v5h4.93v3.729h-4.93v5.039h-4v-5.039H4817.3v-3.729Z"
              fill="#fff"
            />
            <path
              id="Path_119"
              data-name="Path 119"
              d="M4880.819,4297.374h-3.706l-2.878-7.39-2.449-7.424-2.415,7.491-2.813,7.323h-3.705l-8.5-23.515h4.9l5.459,16.965,5.624-16.965h2.846l5.79,16.965,5.294-16.965h4.9Z"
              fill="#fff"
            />
            <path
              id="Path_120"
              data-name="Path 120"
              d="M4915.842,4297.374H4898.5v-23.515h17.338v4.3h-13v5.409h12.539v4.132h-12.539v5.307h13Z"
              fill="#fff"
            />
            <path
              id="Path_121"
              data-name="Path 121"
              d="M4931.514,4273.859v19.417h11.911v4.1h-16.279v-23.515Z"
              fill="#fff"
            />
            <path
              id="Path_122"
              data-name="Path 122"
              d="M4957.169,4273.859v19.417h11.911v4.1H4952.8v-23.515Z"
              fill="#fff"
            />
            <path
              id="Path_123"
              data-name="Path 123"
              d="M4994.305,4289.682v-15.823h4.367v23.515h-3.54l-12.308-15.654v15.654h-4.368v-23.515H4982Z"
              fill="#fff"
            />
            <path
              id="Path_124"
              data-name="Path 124"
              d="M5027.88,4297.374h-17.338v-23.515h17.338v4.3h-13v5.409h12.539v4.132h-12.539v5.307h13Z"
              fill="#fff"
            />
            <path
              id="Path_125"
              data-name="Path 125"
              d="M5052.75,4279.771a6.606,6.606,0,0,0-5.492-2.62c-3.276,0-4.8,1.445-4.8,2.889,0,2.05,2.382,2.587,5.162,2.957,4.863.638,9.33,1.847,9.33,7.491,0,5.24-4.566,7.491-9.727,7.491-4.732,0-8.4-1.478-10.092-5.778l3.64-1.915c1.058,2.587,3.672,3.729,6.518,3.729,2.746,0,5.327-.974,5.327-3.527,0-2.217-2.283-3.091-5.36-3.46-4.732-.538-9.1-1.882-9.1-7.122,0-4.4,4.7-6.618,8.966-6.618,3.64,0,7.312,1.041,9.1,4.669Z"
              fill="#fff"
            />
            <path
              id="Path_126"
              data-name="Path 126"
              d="M5081.336,4279.771a6.608,6.608,0,0,0-5.493-2.62c-3.275,0-4.8,1.445-4.8,2.889,0,2.05,2.382,2.587,5.161,2.957,4.864.638,9.33,1.847,9.33,7.491,0,5.24-4.565,7.491-9.727,7.491-4.731,0-8.4-1.478-10.091-5.778l3.639-1.915c1.059,2.587,3.673,3.729,6.519,3.729,2.746,0,5.326-.974,5.326-3.527,0-2.217-2.283-3.091-5.36-3.46-4.731-.538-9.1-1.882-9.1-7.122,0-4.4,4.7-6.618,8.966-6.618,3.64,0,7.312,1.041,9.1,4.669Z"
              fill="#fff"
            />
            <path
              id="Path_127"
              data-name="Path 127"
              d="M5114.581,4283.836v-5h4v5h4.93v3.729h-4.93v5.039h-4v-5.039h-4.864v-3.729Z"
              fill="#fff"
            />
            <path
              id="Path_128"
              data-name="Path 128"
              d="M5165.064,4289.682v-15.823h4.368v23.515h-3.54l-12.309-15.654v15.654h-4.367v-23.515h3.54Z"
              fill="#fff"
            />
            <path
              id="Path_129"
              data-name="Path 129"
              d="M5201.389,4273.892v13.472c0,6.953-4.2,10.514-10.025,10.514-5.559,0-10.29-3.393-10.29-10.514v-13.472h4.335v13.472c0,4.266,2.382,6.483,5.988,6.483s5.658-2.419,5.658-6.483v-13.472Z"
              fill="#fff"
            />
            <path
              id="Path_130"
              data-name="Path 130"
              d="M5217.967,4277.89h-7.345v-4.031h19.057v4.031h-7.345v19.484h-4.367Z"
              fill="#fff"
            />
            <path
              id="Path_131"
              data-name="Path 131"
              d="M5259.57,4297.374h-5l-6.717-7.86h-4.433v7.86h-4.368v-23.548c3.706,0,7.312.033,11.018.033,5.459,0,8.338,3.762,8.338,7.794,0,3.225-1.456,6.483-5.856,7.39l7.014,8.029Zm-16.146-19.383v7.525h6.65a3.768,3.768,0,1,0,0-7.525Z"
              fill="#fff"
            />
            <path
              id="Path_132"
              data-name="Path 132"
              d="M5269.339,4297.374v-23.515h4.335v23.515Z"
              fill="#fff"
            />
            <path
              id="Path_133"
              data-name="Path 133"
              d="M5290.344,4277.89H5283v-4.031h19.058v4.031h-7.345v19.484h-4.367Z"
              fill="#fff"
            />
            <path
              id="Path_134"
              data-name="Path 134"
              d="M5311.432,4297.374v-23.515h4.335v23.515Z"
              fill="#fff"
            />
            <path
              id="Path_135"
              data-name="Path 135"
              d="M5350.088,4285.751c-.1,6.08-3.739,12.161-11.878,12.161s-11.912-5.946-11.912-12.127c0-7.424,5.261-12.4,12.011-12.4C5346.217,4273.49,5350.187,4279.637,5350.088,4285.751Zm-11.878,8.1c5.459,0,7.477-4.132,7.576-8.029s-2.15-8.3-7.477-8.4c-4.9,0-7.875,3.729-7.709,8.432C5330.7,4289.715,5332.783,4293.847,5338.21,4293.847Z"
              fill="#fff"
            />
            <path
              id="Path_136"
              data-name="Path 136"
              d="M5376.45,4289.682v-15.823h4.367v23.515h-3.54l-12.308-15.654v15.654H5360.6v-23.515h3.54Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HeroLogo
