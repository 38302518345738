import React from "react"

function MondaysSmallIcon() {
  return (
    <div id="mondays-small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 286.89 202.944"
      >
        <path
          id="Path_436"
          data-name="Path 436"
          d="M8744.69,588.039,8713.7,470.887a43.453,43.453,0,0,0-76.436-15.4l-22.629,29.387,14.219,18.464,26.263-34.1a20.92,20.92,0,0,1,36.8,7.415l30.99,117.152c3.063,11.581-3.376,19.415-10.423,22.883s-17.184,3.795-24.492-5.7l-119.744-155.5a43.451,43.451,0,0,0-76.434,15.4l-30.989,117.152c-5.308,20.061,3.636,39.7,22.254,48.86s39.632,4.275,52.293-12.166L8590.84,552.7l-14.218-18.464-59.1,76.747c-7.311,9.491-17.445,9.166-24.492,5.7s-13.487-11.3-10.423-22.884L8513.6,476.649a20.922,20.922,0,0,1,36.8-7.415l119.743,155.5c8.406,10.916,20.492,16.739,33.116,16.739a43.359,43.359,0,0,0,19.177-4.573C8741.053,627.735,8750,608.1,8744.69,588.039Z"
          transform="translate(-8459.313 -438.528)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

export default MondaysSmallIcon
