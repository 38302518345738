import React from "react"
import "../styles/main.scss"
import "../styles/global.css"
import styled from "styled-components"
import Nav from "./Nav/nav"
import Footer from "./Footer/footer"
import Hero from "./Content/Hero/hero"
import Button from "./Button/button"
import { FormProvider } from "../context/formContext"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog`
    const appPath = `${__PATH_PREFIX__}/app`

    return (
      <Wrapper>
        <div>
          <Nav
            secondary={location.pathname !== rootPath}
            blog={location.pathname === blogPath}
            app={location.pathname === appPath}
          />
          <header>
            {location.pathname === rootPath && (
              <Hero>
                <a href="#about">
                  <Button>Own Your Day</Button>
                </a>
              </Hero>
            )}
          </header>
          <FormProvider>
            <main>
              <div className="main-content-container" id="about">
                {children}
              </div>
            </main>
          </FormProvider>
        </div>
        <Footer />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

export default Layout
